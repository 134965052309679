import { React, useState } from 'react';
import {
    Button, MenuItem, TextField, Paper, Grid, Modal, makeStyles,
} from '@material-ui/core';
import { useMsal } from '@azure/msal-react';
import { COLORS } from '../utils/ApplicationConstants';
import { getUserInformation, reshipKit } from '../services/cshApiService';

const useStyles = makeStyles((theme) => ({
    textFieldRoot: {
        width: '100%',
      },
      modalBackground: {
        backgroundColor: COLORS.WHITE,
        margin: '50px auto',
        width: '45%',
        padding: '50px',
        paddingBottom: '30px',
        borderRadius: '15px',
        [theme.breakpoints.down('sm')]: {
          width: '65%',
        },
      },
      buttonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
      },
      formButton: {
        border: 'none',
        backgroundColor: 'transparent',
        color: COLORS.CONGRESS_BLUE,
        letterSpacing: '0.5px',
        textTransform: 'uppercase',
        fontSize: '14px',
        fontFamily: 'Roboto-Medium',
        cursor: 'pointer',
        margin: '32px 0',
      },
      secondaryButton: {
        display: 'block',
        color: COLORS.CONGRESS_BLUE,
        backgroundColor: COLORS.WHITE,
        fontFamily: 'Roboto',
        textTransform: 'uppercase',
        width: 'fit-content',
        margin: '8px auto',
        padding: '13px 37px',
        border: '2px solid',
        borderRadius: '3px',
        letterSpacing: '0.5px',
        cursor: 'pointer',
        transition: '0.2s',
        '&:hover': {
          opacity: '0.9',
        },
      },
      reshipFormWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
}));

const ReshipKitPrompt = ({
    idPayload, kitKey, retriggerLoadOnReship,
}) => {
    const [reshipTrigger, setReshipTrigger] = useState('');
    const [reshipReason, setReshipReason] = useState('');
    const [isReshippable, setIsReshippable] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [userAddress, setUserAddress] = useState({});
    const [isShipButtonDisabled, setShipButtonDisabledStatus] = useState(false);
    const classes = useStyles();
    const msalReference = useMsal();
    const reshipTriggerOptions = ['Kit Not Delivered', 'Expired Sample', 'Expired Kit', 'Others'];

    async function reshipKitToggle() {
      const address = await getUserInformation(idPayload, msalReference);
      setUserAddress(address);
      setShowModal(true);
    }
    async function toggleReship() {
      const reshipPayload = {
        userId: idPayload?.patientId ? idPayload?.patientId : idPayload?.userId,
        kitKeyToCancel: kitKey?.kitKey,
        useSameAddress: true,
        reshipDetails: {
          reshipTrigger,
          reshipReason: reshipReason?.trim(),
        },
      };
      setShipButtonDisabledStatus(true);
      await reshipKit(reshipPayload, msalReference);
      setShowModal(false);
      setShipButtonDisabledStatus(false);
      retriggerLoadOnReship();
    }

    // csh agent must provide reshipment trigger & reason to reship kit
    function bothStringsHaveContent(str1, str2) {
        return str1 && str2 && str1.trim() !== '' && str2.trim() !== '';
    }
    function handleReshipTriggerChange(val) {
        setReshipTrigger(val);
        setIsReshippable(bothStringsHaveContent(val, reshipReason));
    }
    function handleReshipReasonChange(val) {
        setReshipReason(val.substring(0, 100));
        setIsReshippable(bothStringsHaveContent(val.substring(0, 100), reshipTrigger));
    }

    return (
      <>
        <div className={classes.reshipFormWrapper}>
          <p>Reship Kit?</p>
          <form>
            <TextField
              label="Reship Trigger"
              id="reship-type-select"
              value={reshipTrigger}
              onChange={(e) => handleReshipTriggerChange(e.target.value)}
              fullWidth
              required
              select
              variant="outlined"
              margin="dense"
              size="small"
            >
              {reshipTriggerOptions?.map((option) => (
                <MenuItem value={option}>{option}</MenuItem>
              ))}
            </TextField>
            <TextField
              type="text"
              label="Reship Reason"
              id="reship-reason-text"
              value={reshipReason}
              helperText={`${reshipReason.length}/100`}
              onChange={(e) => handleReshipReasonChange(e.target.value)}
              fullWidth
              required
              multiline
              minRows={3}
              maxRows={3}
              variant="outlined"
              margin="dense"
              size="small"
            />
            <Button
              className={classes.secondaryButton}
              onClick={reshipKitToggle}
              disabled={!isReshippable}
            >
              Reship Kit
            </Button>
          </form>
        </div>
        <Modal
          open={showModal}
          onClose={() => setShowModal(false)}
        >
          <Paper classes={{ root: classes.modalBackground }}>
            <form>
              <div className={classes.gridRoot}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      classes={{ root: classes.textFieldRoot }}
                      required
                      label="First Name"
                      value={userAddress?.details?.firstName}
                      name="firstName"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      classes={{ root: classes.textFieldRoot }}
                      required
                      name="lastName"
                      label="Last Name"
                      value={userAddress?.details?.lastName}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      classes={{ root: classes.textFieldRoot }}
                      required
                      label="E-Mail"
                      value={userAddress?.details?.email}
                      name="email"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      classes={{ root: classes.textFieldRoot }}
                      required
                      name="phoneNumber"
                      label="Phone Number"
                      value={userAddress?.phone?.phoneNumber}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      classes={{ root: classes.textFieldRoot }}
                      required
                      label="Address Line 1"
                      name="addressLine1"
                      value={userAddress?.address?.addressLine1}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      classes={{ root: classes.textFieldRoot }}
                      label="Address Line 2"
                      name="addressLine2"
                      value={userAddress?.address?.addressLine2}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <TextField
                      classes={{ root: classes.textFieldRoot }}
                      label="City"
                      name="city"
                      value={userAddress?.address?.city}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      classes={{ root: classes.textFieldRoot }}
                      label="State"
                      name="state"
                      value={userAddress?.address?.state}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      classes={{ root: classes.textFieldRoot }}
                      label="Zip"
                      name="zipCode"
                      value={userAddress?.address?.zipCode}
                      disabled
                    />
                  </Grid>
                </Grid>
              </div>
            </form>
            <div className={classes.buttonContainer}>
              <button
                type="button"
                className={classes.formButton}
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                disabled={isShipButtonDisabled}
                className={classes.formButton}
                onClick={toggleReship}
              >
                Reship
              </button>
            </div>
          </Paper>
        </Modal>
      </>
    );
};

export default ReshipKitPrompt;
