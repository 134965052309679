import React from 'react';
import { makeStyles } from '@material-ui/core';
import checkMark from '../images/checkMark.svg';

const useStyles = makeStyles(() => ({
  journeyItemRoot: {
    display: 'flex',
    alignItems: 'self-start',
    position: 'relative',
    '&:not(:last-child)': {
      '&::before': {
        content: "''",
        width: '1px',
        position: 'absolute',
        top: '30px',
        left: '11px',
        height: 'calc(100% - 36px)',
        backgroundColor: '#DDDDDD',
      },
    },
  },
  reShippableSpacing: {
    paddingBottom: '20px',
  },
  checkMarkImage: {
    marginRight: '12px',
  },
  journeyDescription: {
    paddingBottom: '40px',
    paddingTop: '3px',
    marginTop: '0',
    fontSize: '14px',
    fontWeight: '500',
  },
  journeyContainer: {
    display: 'flex',
    flexFlow: 'column',
  },
}));

const JourneyItem = ({
  journeyDescription,
}) => {
  const classes = useStyles();
  const { customerJourneyStep } = journeyDescription;

  return (
    <div className={classes.journeyItemRoot}>
      <img className={classes.checkMarkImage} src={checkMark} alt="Checkmark Icon" />
      <div className={classes.journeyContainer}>
        <p className={classes.journeyDescription}>{customerJourneyStep}</p>
      </div>
    </div>
  );
};

export default JourneyItem;
