import React from 'react';
import { makeStyles, Paper } from '@material-ui/core';
import JourneyItem from './JourneyItem';
import { COLORS } from '../utils/ApplicationConstants';
import ReshipKitPrompt from './ReshipKitPrompt';

const useStyles = makeStyles((theme) => ({
  journeyPanelRoot: {
    padding: theme.spacing(4),
    color: theme.palette.text.primary,
  },
  panelTitle: {
    color: COLORS.NERO,
    fontSize: '16px',
  },
  errorHeader: {
    fontSize: '18px',
    color: COLORS.NERO,
    fontWeight: '500',
    marginTop: '57px',
    marginBottom: '0',
  },
}));

// eslint-disable-next-line object-curly-newline
const JourneyPanel = ({ userJourney, idPayload, kitKey, retriggerLoadOnReship }) => {
  const classes = useStyles();
  const { customerJourneySteps } = userJourney;
  if (!customerJourneySteps) {
    return (
      <Paper classes={{ root: classes.journeyPanelRoot }}>
        <h1 className={classes.errorHeader}>No Current Journey Map</h1>
      </Paper>
    );
  }
  return (
    <Paper classes={{ root: classes.journeyPanelRoot }}>
      <p className={classes.panelTitle}>Customer&apos;s Journey</p>
      {customerJourneySteps.map((item) => (
        <JourneyItem journeyDescription={item} />
      ))}
      <ReshipKitPrompt
        kitKey={kitKey}
        idPayload={idPayload}
        retriggerLoadOnReship={retriggerLoadOnReship}
      />
    </Paper>
  );
};

export default JourneyPanel;
